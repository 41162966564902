const { web3 } = require('../web3');
const { env } = require('../config');

const NFTData = require(`./${env}/NFT.json`);
const SNFTData = require(`./${env}/SNFT.json`);
const ERC1155 = require(`./${env}/ERC1155.json`);
const ApproveData = require(`./${env}/Approve.json`);
const Donations = require(`./${env}/Donations.json`);
const RewardNFTData = require(`./${env}/RewardNFT.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const NFTABI = NFTData['abi'];
const NFTBytecode = NFTData['bytecode'];

const RewardNFTABI = RewardNFTData['abi'];
const RewardNFTBytecode = RewardNFTData['bytecode'];

const SNFTABI = SNFTData['abi'];
const SNFTAddress = SNFTData['address'];
const SNFT = new web3.eth.Contract(SNFTABI, SNFTAddress);

const ApproveABI = ApproveData['abi'];
const ApproveAddress = ApproveData['address'];
const Approve = new web3.eth.Contract(ApproveABI, ApproveAddress);

module.exports = {
  NFTABI, NFTBytecode,
  SNFT, SNFTABI, SNFTAddress,
  RewardNFTABI, RewardNFTBytecode,
  Approve, ApproveABI, ApproveAddress,
  ERC1155Abi: ERC1155['abi'], ERC1155ByteCode: ERC1155['bytecode'],
  DonactionsAbi: Donations['abi'], DonactionsByteCode: Donations['bytecode']
};
