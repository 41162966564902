import Deploy from "./views/Deploy/index.js";
import Profile from "./views/Profile/index.js";
import CreateNft from "./views/CreateNft/index.js";
import TXDetails from "./views/TXDetails/index.js";
import Deploy1155 from "./views/Deploy1155/index.js";
import AirdropReward from "./views/AirdropReward/index.js";
import CreateRewardNft from "./views/CreateRewardNft/index.js";
import DonationsDetailNew from "./views/DonationsDetailNew/index.js";
import DonationsDetailSteps from "./views/DonationsDetailSteps/index.js";
import UpdateDonationsDetail from "./views/UpdateDonationsDetail/index.js";



var routes = [
  {
    layout: "/home",
    path: "/",
    component: Deploy1155,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/DeployERC1155",
    name: "XRC-1155 Token",
    component: Deploy1155,
    discription: "Deploy XRC-1155 Token",
    img: "/images/erc.svg",
    activeImg: "/images/ercWhite.svg",
  },
  {
    layout: "/home",
    path: "/DeployContract",
    name: "Donations",
    component: Deploy,
    discription:"Deploy Donation",
    img: "/images/donation.svg",
    activeImg: "/images/donationWhite.svg",
  },
  {
    layout: "/home",
    path: "/NewDonationsDetail",
    name: "Add Donations",
    component: DonationsDetailNew,
    discription:"Add Donations Details New",
    img: "/images/addonation.svg",
    activeImg:"/images/addonationWhite.svg",
  },
  {
    layout: "/home",
    path: "/NewDonationsDetailSteps",
    name: "Add Donations",
    component: DonationsDetailSteps,
    hidden: true,
  },
  // {
  //   layout: "/home",
  //   path: "/UpdateDonationsDetail",
  //   name: "Update Donations",
  //   component: UpdateDonationsDetail,
  //   discription:"Update Donation Details",
  //   img: "/images/donationData.svg",
  //   activeImg: "/images/donationDataWhite.svg",
  // },
  {
    layout: "/home",
    path: "/TXDetails",
    name: "Donations Data",
    component: TXDetails,
    discription:"Info About Donations",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/profile",
    name: "Profile",
    component: Profile,
    discription:"User Profile",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/CreateNft",
    name: "Create Charity NFT",
    component: CreateNft,
    discription:"Create Charity NFT",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/CreateRewardNft",
    name: "Create Reward NFT",
    component: CreateRewardNft,
    discription:"Create Reward NFT",
    img: "/images/donationData.svg",
    activeImg: "/images/donationDataWhite.svg",
  },
  {
    layout: "/home",
    path: "/AirdropReward",
    name: "Airdrop Reward NFT",
    component: AirdropReward,
    discription:"Airdrop Reward NFT",
    img: "/images/approved.svg",
    activeImg: "/images/approved.svg",
  },
];

export default routes;