import axios from "axios";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./index.css";

import { web3 } from "../../store/web3.js";
import { networkId, ApiUrl } from "../../store/config.js";
import { RewardNFTABI, RewardNFTBytecode } from "../../store/contract/index.js";
import { setLoader } from "../../store/actions/Auth.js";

function CreateRewardNft(props) {

  const [tokenUri, settokenUri] = useState("");
  const [tokenName, settokenName] = useState("");
  const [tokenSupply, settokenSupply] = useState("");
  const [tokenBanner, settokenBanner] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [tokenDescription, settokenDescription] = useState("");

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenName") {
      settokenName(e.target.value);
    } else if ([e.target.name] == "tokenSymbol") {
      settokenSymbol(e.target.value);
    } else if ([e.target.name] == "tokenUri") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        settokenUri(inputValue)
      } else {
        settokenUri(e.target.value);
      }
    } else if ([e.target.name] == "tokenDescription") {
      settokenDescription(e.target.value);
    } else if ([e.target.name] == "tokenSupply") {
      if (parseInt(e.target.value) > 0) {
        settokenSupply(parseInt(e.target.value));
      } else {
        settokenSupply("");
      }
    } else if ([e.target.name] == "tokenBanner") {
      settokenBanner(e.target.files[0]);
    }
  }

  async function getIPFSData(url) {
    return new Promise(async (resolve, reject) => {
      try {
        if (url && !url.endsWith("/")) url = url + "/";
        let data = await fetch(`${ApiUrl}/validateURI`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
          },
          body: JSON.stringify({ url: `${url}1` }), // Convert your data to JSON format if needed
        });
        if (parseInt(data.status) == 200) {
          data = await data.json();
          return resolve(true);
        }
        if (parseInt(data.status) == 400) {
          let data = await fetch(`${ApiUrl}/validateURI`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
            },
            body: JSON.stringify({ url: `${url}1.json` }), // Convert your data to JSON format if needed
          });
          if (parseInt(data.status) == 200) {
            return resolve(true);
          } else if (parseInt(data.status) == 400) {
            let data = await fetch(`${ApiUrl}/validateURI`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
              },
              body: JSON.stringify({ url }), // Convert your data to JSON format if needed
            });
            if (parseInt(data.status) == 200) {
              return resolve(true);
            }
            else return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    });
  }

  async function deploy(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let deployer = (await web3.currentProvider.enable())[0];

      if (tokenName == "") {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token name must contain alphabets`);
        return;
      }

      if (tokenSymbol == "") {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token symbol must contain alphabets`);
        return;
      }

      if (tokenSupply == "") {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (tokenUri == "") {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Base URI must contain alphabets`);
        return;
      }

      let checkUrl = await getIPFSData(tokenUri);
      if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

      if (tokenDescription == "") {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token description must contain alphabets`);
        return;
      }

      if (tokenBanner == "") {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (tokenBanner == undefined) {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (deployer == null || deployer == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = deployer;
      let output = deployer.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + deployer.substring(3);
      } else {
        from = deployer;
      }

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Deployment in Progress...",
        status: true,
      });

      let tokenType = "XRC721"

      console.log("*** tokenName :: ", tokenName);
      console.log("*** tokenSymbol :: ", tokenSymbol);
      console.log("*** tokenDescription :: ", tokenDescription);
      console.log("*** tokenSupply :: ", tokenSupply);
      console.log("*** tokenType :: ", tokenType);
      console.log("*** from :: ", from);
      console.log("*** banner :: ", tokenBanner);
      console.log("*** network :: ", parseInt(networkId));

      let contract = new web3.eth.Contract(RewardNFTABI);
      let deploy = contract.deploy({ data: RewardNFTBytecode, arguments: [tokenName, tokenSymbol, tokenSupply, tokenUri] });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {

          let data = new FormData();
          data.append('tokenName', tokenName);
          data.append('tokenSymbol', tokenSymbol);
          data.append('tokenDescription', tokenDescription);
          data.append('tokenAddress', receipt['contractAddress']);
          data.append('tokenSupply', tokenSupply);
          data.append('tokenType', tokenType);
          data.append('from', from);
          data.append('banner', tokenBanner);
          data.append('network', parseInt(networkId));

          axios({
            method: "post",
            url: `${ApiUrl}/deployRewardToken`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              props.setLoader({
                message: "Contract Deploy...",
                status: false,
              });
              console.log(response['data']['message']);
              EventBus.publish("success", `${response['data']['message']}: ${receipt['contractAddress']}`);
              waitFor(1500);
              settokenAddress(receipt['contractAddress']);
              settokenName("");
              settokenSymbol("");
              settokenUri("");
              settokenDescription("");
              settokenSupply("");
              settokenBanner("");
              console.log(`************** deploy contract address = `, receipt['contractAddress'])
            })
            .catch(function (error) {
              props.setLoader({
                message: "Contract Deploy...",
                status: false,
              });
              console.log(error['response']['data']['message']);
              EventBus.publish("error", error['response']['data']['message']);
            });
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }


  return (
    <div className="content">
      <section className="profile">
        <div class="tab-content">
          <div className="signin">
            {
              tokenAddress !== "" &&
              <div className="text-left mt-3">
                <CopyToClipboard text={tokenAddress} onCopy={copiedAddress}>
                  <a className="public-address">{`XRC-1155 Contract Address: ${tokenAddress}`}</a>
                </CopyToClipboard>
              </div>
            }
            <form className="create" onSubmit={deploy}>
              <h2>Create a collection</h2>

              <div className="form-group" controlId="Name">
                <label>Name</label>
                <input
                  type="text"
                  name='tokenName'
                  value={tokenName}
                  onChange={handleOnInput}
                  maxlength="42"
                  placeholder="Example “MARS NFT”"
                />
              </div>

              <div className="form-group" controlId="Symbol">
                <label>Symbol</label>
                <input
                  type="text"
                  name='tokenSymbol'
                  value={tokenSymbol}
                  onChange={handleOnInput}
                  placeholder="Example “$MARS$”"
                  maxlength="10"
                />
              </div>

              <h2>Banner image</h2>

              <p>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</p>

              <div className="form-group" controlId="formBasicFame">

                <div className="upload-file">
                  <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Drag and drop file here"}</span>

                  <input
                    type="file"
                    name='tokenBanner'
                    placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                    accept="image/*"
                    onChange={handleOnInput}
                  />

                  <button className="common-btn border-white">Choose File</button>
                </div>

                <span>Files supported: PNG, JPEG or JPG. Max 200mb.</span>
              </div>

              <div className="form-group" controlId="URL">
                <label>Base URI*</label>
                <p>Customize your URL on Marketplace. Must only contain lowercase letters,numbers, and hyphens.</p>
                {
                  parseInt(networkId) == 51 ?
                    <a href="https://dhegch1bc76pc.cloudfront.net/" target="_blank">Click here to generate your Base URI</a>
                    :
                    <a href="https://tool.cifiapp.com/" target="_blank">Click here to generate your Base URI</a>
                }
                <input
                  type="text"
                  name='tokenUri'
                  value={tokenUri}
                  onChange={handleOnInput}
                  maxlength="250"
                  placeholder="https://marketplace.com/collection/mars-stones"
                />
              </div>

              <div className="form-group" controlId="Name">
                <label>Total Supply</label>
                <p>Enter your NFT collection supply here.</p>
                <input
                  type="number"
                  name='tokenSupply'
                  value={tokenSupply}
                  onChange={handleOnInput}
                  onWheel={handleOnWheel}
                  placeholder="1000000"
                />
              </div>

              <div className="form-group" controlId="Desc">
                <label>Description</label>
                <p>Markdown syntax is supported. 0 of 1000 characters used.</p>
                <textarea
                  as="textarea"
                  rows={3}
                  name='tokenDescription'
                  value={tokenDescription}
                  onChange={handleOnInput}
                  maxlength="1000"
                  placeholder="Description* (Max 250 Characters)"
                />
              </div>

              <button className="common-btn create-btn" type="submit">Create</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { address } = Auth;
  return { address };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateRewardNft);
