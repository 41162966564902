import axios from 'axios';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3.js";
import { toFixed } from "../../store/helper";
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

import { setLoader, getUser, getUriData, getRewardTokens } from "../../store/actions/Auth.js";
import { SNFT, NFTABI, RewardNFTABI } from "../../store/contract/index.js";
import { networkId } from "../../store/config.js";
import collectionimg from "../../assets/img/collection-img.png";
import NFT from "../../assets/img/NFT.gif";
import './index.css';
import modalcloseicon from "../../assets/img/close.png";
import copyicon from "../../assets/img/copy.png";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
function Profile(props) {

  let [bSymbol, setBSymbol] = useState("");
  let [data, setData] = useState({});
  let [donations, setDonations] = useState([]);
  let [rewardNFTs, setRewardNFTs] = useState([]);
  let [ownedSNFT, setownedSNFT] = useState([]);
  let [baseURINFT, setBaseURINFT] = useState("");
  let [walletAddress, setWalletAddress] = useState([]);
  let [ownedCollections, setOwnedCollections] = useState([]);
  const [transferModal, setTransferModal] = useState(false);

  useEffect(() => {
    if (props.address !== null && props.address !== undefined) {
      if (networkId == 51) {
        setBSymbol("TXDC");
        setBaseURINFT(`https://apothem.blocksscan.io/api/tokens/holding/xrc721/${props.address}`);
      } else if (networkId == 50) {
        setBSymbol("XDC");
        setBaseURINFT(`https://xdc.blocksscan.io/api/tokens/holding/xrc721/${props.address}`);
      }
    }
  }, [networkId, props.address]);

  useEffect(() => {
    if (props.address && web3) {
      if (baseURINFT) getOwnedNFTs();
    }
  }, [baseURINFT, props.address, web3])

  async function getOwnedNFTs() {
    try {
      let array1 = [];
      let response = await axios.get(baseURINFT);
      response = response['data'];
      let temp = response.items
      if (temp && temp.length > 0) {
        let list = [];
        for (let items of temp) {
          let tokenAddress = items['token'];
          let output = tokenAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
          if (output == "xdc") {
            tokenAddress = "0x" + tokenAddress.substring(3);
          } else {
            tokenAddress = tokenAddress;
          }
          let tokenId = items['tokenId'];
          let tokenName = items['tokenObj']['name'];
          let tokenSymbol = items['tokenObj']['symbol'];
          let contract = new web3.eth.Contract(NFTABI, tokenAddress);
          let data = await contract.methods.tokenURI(tokenId).call();
          if (data.split("/")[2] == 'gateway.pinata.cloud') {
            const parts = data.split(`${items['tokenId']}.json`);
            const newUrl = parts[0];
            data = newUrl;
          }
          list.push(data);
          array1.push({ tokenAddress, tokenId, tokenName, tokenSymbol });
        };
        props.getUriData({ list });
        setOwnedCollections(array1);
        props.setLoader({
          message: "Loading Data...",
          status: false,
        });
      } else {
        props.setLoader({
          message: "Loading Data...",
          status: false,
        });
      }
    } catch (e) {
      props.setLoader({
        message: "Loading Data...",
        status: false,
      });
    }
  }

  useEffect(() => {
    if (props.address && web3) {
      if (props.uriData) updateData();
    }
  }, [props.uriData, props.address, web3]);

  async function updateData() {
    if (props && props.uriData.length > 0) {
      if (ownedCollections.length == props.uriData.length) {
        let temp = ownedCollections.map((item, idx) => {
          return { ...item, ...props.uriData[idx] };
        })
        setOwnedCollections(temp);
      }
    }
  }

  useEffect(() => {
    if (props.address) {
      props.getUser({ publicAddress: props.address });
      props.getRewardTokens();
      getSPData();
    }
  }, [props.address]);

  async function getSPData() {
    let address = (await web3.currentProvider.enable())[0];
    let owned = await SNFT.methods.walletOfOwner(address).call();
    setownedSNFT(owned);
  }

  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      console.log("*** props.userData", props.userData);
      if (props.userData[0].donations) {
        setDonations(props.userData[0].donations);
      }
    }
  }, [props.userData]);

  useEffect(() => {
    if (props.rewardTokens && props.rewardTokens.length > 0) {
      console.log("*** props.rewardTokens", props.rewardTokens);
      setRewardNFTs(props.rewardTokens);
    }
  }, [props.rewardTokens]);

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    ownedCollections[idx]['image'] = collectionimg;
    setOwnedCollections([...ownedCollections]);
  };

  async function setInstance2(item) {
    // setMicroeconomyTransferModal(true);
    setData(item);
  }

  async function handleChange(e) {
    if ([e.target.name] == "walletAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setWalletAddress(result);
      } else {
        setWalletAddress(e.target.value);
      }
    }
  }

  async function transferNFT(e) {
    try {
      e.preventDefault();

      let { address } = props;

      if (walletAddress == "") {
        EventBus.publish("error", `Enter wallet address!`);
        return;
      }

      if (!walletAddress.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter wallet address`);
        return;
      }

      if (!walletAddress.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Wallet Address`);
        return;
      }

      if (address == null || address == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let from = address;
      let output = address.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + address.substring(3);
      } else {
        from = address;
      }

      props.setLoader({
        message: "Approval in Progress...",
        status: true,
      });

      let contract = new web3.eth.Contract(NFTABI, data['tokenAddress']);
      await contract.methods.approve(walletAddress, parseInt(data['tokenId'])).send({
        from: deployer,
      });

      props.setLoader({
        message: "NFT Transfer in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: data['tokenAddress'],
          gas: 5000000,
          data: contract.methods
            .safeTransferFrom(deployer, walletAddress, parseInt(data['tokenId']))
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploTransferredy contract hash = ${hash}`);
        })
        .on('receipt', async receipt => {
          getOwnedNFTs();
          setWalletAddress("");
          setData({});
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT Transferred Successfully!`);
          // setMicroeconomyMintModal(false);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "NFT Transferred Failed...",
        status: false,
      });
      setWalletAddress("");
      // setMicroeconomyMintModal(false);
      EventBus.publish("error", `NFT Transferred Failed`);
    }
  };

  console.log("*** props.userData :: ", props.userData);
  console.log("*** donations :: ", donations);
  console.log("*** ownedSNFT :: ", ownedSNFT);
  console.log("*** rewardNFTs :: ", rewardNFTs);

  return (
    <div className="content">
      {/* <div className="profile-wrapper">
          <div className="inner">
            <div className="inner-wrap">
              <div className="img-wrap">
                <img src={uploadimg} className="upload-img" alt="upload-img" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />

                <div className="selected-image">
                  {selectedImage && (
                      <img src={URL.createObjectURL(selectedImage)} alt="Selected"/>
                  )}
                </div>
              </div>

              <div className="right">
                <div className="content">
                  <h2>Profile Name</h2>

                  <p>This page includes all NFTs and Tokens you have created with your wallet.</p>
                </div>

                <div className="wallet-address-wrap">
                  <div className="wallet-address">
                    <span>d23d2d6ts6das6das65dasdad5as6</span>

                    <img src={walletaddresscopyicon} alt=""  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      <div className="collection-wrap">
        <h2>NFTs</h2>

        {
          donations.length > 0 && donations.map((item, idx) => (
            <div className="box">
              <img src={NFT} alt=" " />

              <div className="content">
                <h3>Donation NFT</h3>
                <p>Name: {item['donationName']}</p>
                <p>Method: {item['donationType']}</p>
                <p>Amount: {toFixed(item['donationAmount'], "price")}</p>
              </div>
            </div>
          ))
        }

        {
          ownedCollections.length > 0 && ownedCollections.map((item, idx) => (
            <div className="box">
              <img key={idx} src={item['image'] ? item['image'] : collectionimg} onError={() => handleImageError(idx)} alt="Alternate Image" />

              <div className="content">
                <h3>{item['tokenSymbol']} #{item['tokenId']}</h3>
                <p>{item['tokenName']}</p>
              </div>
              {/* <div className='wallet-address mt-3 mb-3'>
                <p>{item['tokenAddress'] && item['tokenAddress'].substring(0, 5) + '...' + item['tokenAddress'].substring(37, item['tokenAddress'].length)}</p>
                <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item['tokenAddress'])} />
              </div> */}
              {/* <button className="transfer" onClick={() => setInstance2(item)}>Transfer</button> */}
            </div>
          ))
        }

        {/* {
          ownedSNFT.length > 0 && ownedSNFT.map((item, idx) => (
            <div className="box">
              <img src={collectionimg} alt=" " />

              <div className="content">
                <h3>S-NFT #{item}</h3>
                <p>Special NFT</p>
              </div>
            </div>
          ))
        } */}
      </div>

      <Modal
        open={transferModal}
        onClose={() => setTransferModal(false)}
        classNames={{
          modal: `common-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img 
          src={modalcloseicon}
            onClick={() => setTransferModal(false)}
          />
        </button>

        <div className="modal-body">
          <h2>Transfer NFT</h2>

          <div className="form-group">
            <input
              type="text"
              placeholder="Add NFT Contract Address*"
            />
          </div>

          <div className="edit-add-buttons">
            <Button
              className="submit-btn"
              type="submit"
            >
              Transfer
            </Button>
          </div>
        </div>  
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getUser,
  getUriData,
  getRewardTokens
};

const mapStateToProps = ({ Auth }) => {
  let { userData, rewardTokens, address, uriData } = Auth;
  return { userData, rewardTokens, address, uriData };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);